<template>
  <div class="loginForm">
    <!-- logo -->
    <div class="loginForm-logo" />
    <div class="loginForm-border" />
    <!-- 密码登录/短信登录切换 -->
    <div class="loginForm-title" v-if="title !== 'register'">
      <!-- <div class="loginForm-title-text">
        <el-button
          type="text"
          :class="title === 'passLogin' ? 'btn1' : 'btn'"
          @click="loginMethod('passLogin')"
          >密码登录</el-button
        >
        <div class="loginForm-title-border" v-show="title === 'passLogin'" />
      </div> -->
      <!-- <div class="loginForm-title-text">
        <el-button
          type="text"
          :class="title === 'noteLogin' ? 'btn1' : 'btn'"
          @click="loginMethod('noteLogin')"
          >短信登录</el-button
        >
        <div class="loginForm-title-border" v-show="title === 'noteLogin'" />
      </div> -->
    </div>
    <!-- 注册 -->
    <div class="loginForm-title" v-else>
      <div class="loginForm-title-register">
        <el-button
          type="text"
          class="btn"
          icon="el-icon-arrow-left"
          @click="loginMethod('passLogin')"
          >返回</el-button
        >
        <el-button type="text" class="register btn">注册账号</el-button>
      </div>
    </div>
    <!-- 表单 -->
    <el-form class="loginForm-form" :model="loginData" ref="loginRuleForm">
      <!-- 手机号码 -->
      <el-form-item prop="phone" class="loginForm-form-phone">
        <el-input
          v-model.trim="loginData.phone"
          maxlength="11"
          placeholder="请输入手机号"
          clearable
        >
          <span class="loginForm-form-phone-text" slot="prefix">+86</span>
          <!-- <country-code-selector slot="prefix" class="selector" /> -->
        </el-input>
      </el-form-item>
      <!-- 验证码 -->
      <el-form-item prop="code" v-show="title !== 'passLogin'">
        <el-input
          v-model.trim="loginData.code"
          maxlength="6"
          placeholder="请输入验证码"
        >
          <i slot="prefix" class="icon login-verify"></i>
          <el-button
            slot="suffix"
            :disabled="codeTime !== '获取验证码'"
            @click="getCodeClick('loginRuleForm')"
            type="text"
            class="loginForm-form-btn-code"
            >{{ codeTime
            }}<span v-if="codeTime !== '获取验证码'">秒后重试</span></el-button
          >
        </el-input>
      </el-form-item>
      <!-- 密码 -->
      <el-form-item prop="password" v-show="title === 'passLogin'">
        <el-input
          v-model.trim="loginData.password"
          placeholder="请输入密码"
          oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
          maxlength="20"
          show-password
          clearable
        >
          <i slot="prefix" class="icon login-pass"></i>
        </el-input>
      </el-form-item>
      <!-- 新用户注册和忘记密码 -->
      <div class="loginForm-form-password" v-show="title === 'passLogin'">
        <div @click="loginMethod('register')">新用户注册</div>
        <el-dropdown
          trigger="click"
          class="loginForm-form-password-forget"
          @command="loginMethod('noteLogin')"
        >
          <span>忘记密码？</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>通过短信快速登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 登录按钮 -->
      <el-form-item>
        <el-button
          type="primary"
          class="loginForm-form-btn"
          @click="handleLogin('loginRuleForm')"
          @keyup.enter.native="enterLogin()"
          >{{ title === 'register' ? '注册' : '登录/注册' }}</el-button
        >
      </el-form-item>
      <!-- 用户协议 -->
      <div class="loginForm-form-userAgreement">
        <el-checkbox v-model="consent" />
        <span
          >&ensp;我已阅读并同意<span class="agreement" @click="checkAgreement"
            >《用户协议》</span
          ></span
        >
      </div>
    </el-form>
    <!-- 提示 -->
    <div class="loginForm-message" v-show="messageBox">
      {{ messageText }}
    </div>
  </div>
</template>

<script>
// 国际区号组件
// import countryCodeSelector from 'c/countryCodeSelector/components/index.vue';
export default {
  name: 'LoginForm',
  components: {
    // countryCodeSelector
  },
  data() {
    return {
      title: 'noteLogin', // 登录/注册切换
      // 表单数据
      loginData: {
        phone: '',
        code: '',
        password: '',
        client: 'web'
      },
      areaCode: '', //国际区号
      codeTime: '获取验证码', // 取验证码的内容显示
      codeCountDown: null, //验证码倒计时
      consent: false, // 同意用户协议
      messageText: '', // 提示语
      messageBox: false // 提示窗口
    };
  },
  created() {
    if (this.title === 'passLogin') {
      this.getCookie();
    }

    //初始化回车事件
    this.enterLogin();
  },
  methods: {
    checkAgreement() {
      window.open(window.agreement, 'checkAgreement');
    },
    // 登录方式
    loginMethod(val) {
      this.title = val;
      this.loginData = {
        phone: '',
        code: '',
        password: '',
        client: 'web'
      };
      if (val === 'passLogin') {
        this.getCookie();
      }
    },
    // 登录
    handleLogin() {
      if (this.title === 'passLogin') {
        this.handleMessageBox('密码登录暂未开放，将为您跳转到短信登录');
        setTimeout(() => {
          this.title = 'noteLogin';
        }, 1500);
        return;
      }
      const regPhone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!this.loginData.phone) {
        return this.handleMessageBox('请输入手机号码');
      } else if (!regPhone.test(this.loginData.phone)) {
        return this.handleMessageBox('手机号码格式不正确，请重新输入');
      }
      if (this.title === 'passLogin') {
        if (!this.loginData.password) {
          return this.handleMessageBox('请输入密码');
        }
      } else {
        if (!this.loginData.code) {
          return this.handleMessageBox('请输入验证码');
        } else if (this.loginData.code.length !== 6) {
          return this.handleMessageBox('验证码格式不正确，请重新输入');
        }
      }
      if (!this.consent) {
        return this.handleMessageBox('请阅读并同意协议');
      }
      const params = this.loginData;
      for (var key in params) {
        params[key] === '' && delete params[key];
      }

      this.$service.login.login(params).then(data => {
        if (data.data.status === 200) {
          window.localStorage.setItem('token', data.data.data.token);
          localStorage.userName = this.loginData.phone;
          this.$message.success(data.data.msg);
          this.$store.commit('userData/setLoginData', localStorage.userName);

          const redirectUrl = localStorage.redirectUrl;
          if (redirectUrl) {
            if (redirectUrl.indexOf('workbench') !== -1) {
              window.open(redirectUrl, 'workbench');
            } else if (redirectUrl.indexOf('login') !== -1) {
              this.$router.push('/');
            } else {
              window.location.href = localStorage.redirectUrl;
            }
          } else {
            this.$router.push('/');
          }
          // 密码登录 接口请求成功保存
          if (this.title === 'passLogin') {
            this.setCookie(
              this.loginData.phone,
              this.loginData.password,
              7 //保存七天
            );
          }
        }
      });
    },
    // 发送验证码&&倒计时
    getCodeClick() {
      const regPhone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!this.loginData.phone) {
        return this.handleMessageBox('请输入手机号码');
      } else if (!regPhone.test(this.loginData.phone)) {
        return this.handleMessageBox('手机号码格式不正确，请重新输入');
      }
      if (!this.consent) {
        return this.handleMessageBox('请阅读并同意协议');
      }
      var params = {
        phone: this.loginData.phone,
        captchaAngle: 1,
        captchaId: 1
      };
      this.$service.login.sendCode(params).then(data => {
        if (data.data.status === 200) {
          const TIME_COUNT = 59; //倒计时60秒
          if (!this.codeCountDown) {
            this.codeTime = TIME_COUNT;
            this.codeCountDown = setInterval(() => {
              if (this.codeTime > 0 && this.codeTime <= TIME_COUNT) {
                this.codeTime--;
              } else {
                this.codeTime = '获取验证码';
                clearInterval(this.codeCountDown);
                this.codeCountDown = null;
              }
            }, 1000);
          }
          this.handleMessageBox('验证码已发送，请注意查收短信');
        }
      });
    },
    // 设置cookie
    setCookie(username, password, exdays) {
      var exdate = new Date(); // 获取当前登录的时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 将当前登录的时间加上七天，就是cookie过期的时间，也就是保存的天数
      // 字符串拼接cookie,因为cookie存储的形式是name=value的形式
      window.document.cookie =
        'userName' + '=' + username + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie =
        'userPwd' + '=' + password + ';path=/;expires=' + exdate.toGMTString();
    },
    // 清除cookie
    clearCookie() {
      this.setCookie('', '', -1); // 清空并设置天数为负1天
    },
    // 获取cookie的账号密码
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=');
          // 判断查找相对应的值
          if (arr2[0] === 'userName') {
            this.loginData.phone = arr2[1]; // 转存一份保存用户名和密码
          } else if (arr2[0] === 'userPwd') {
            this.loginData.password = arr2[1]; //可解密
          }
        }
      }
    },
    //这是定义的触发回车的事件 此函数必须初始化的时候执行一次此函数 否则回车事件不生效
    enterLogin() {
      document.onkeydown = e => {
        e = window.event || e;
        if (
          this.$route.path == '/login' &&
          (e.code == 'Enter' || e.code == 'enter')
        ) {
          //调用登录事件方法
          this.handleLogin();
        }
      };
    },
    // 提示框
    handleMessageBox(val) {
      this.messageText = val;
      this.messageBox = true;
      setTimeout(() => {
        this.messageBox = false;
      }, 2000);
    }
  }
};
</script>

<style lang="less" scoped>
.loginForm {
  height: 500px;
  width: 420px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  &-logo {
    margin: 30px auto 0;
    width: 178px;
    height: 43px;
    background-size: contain;
    background-image: url('~a/login-logo.png');
  }
  // 分割线
  &-border {
    margin: 20px auto 0;
    width: 380px;
    height: 1px;
    background: #e9f2fb;
  }
  // 标题
  &-title {
    display: flex;
    align-content: space-between;
    margin-top: 30px;
    &-text {
      text-align: center;
      width: 49%;
      .btn1 {
        font-size: 20px;
        font-weight: bold;
        color: #0096ff;
      }
    }
    // 注册
    &-register {
      margin: 0 20px 53px;
      .register {
        margin-left: 80px;
      }
    }
    .btn {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
    }
    &-border {
      margin: 18px auto 34px;
      width: 50px;
      height: 1px;
      background: #0096ff;
    }
  }
  // 表单
  /deep/&-form {
    margin: 20px 30px 0;
    .el-form-item {
      margin: 0 0 30px;
      &__error {
        margin-top: 5px;
        font-size: 12px;
      }
    }
    //输入框
    .el-input__inner {
      height: 52px;
      padding-left: 50px;
    }

    // 按钮
    &-btn {
      margin-top: 20px;
      width: 100%;
      height: 40px;
      font-size: 20px;
      &-code {
        line-height: 53px;
        margin-right: 10px;
        font-size: 16px;
        color: #0096ff;
      }
    }
    // 手机号码输入框
    &-phone {
      &-text {
        user-select: none;
        font-size: 18px;
        line-height: 52px;
        color: #666;
        margin-left: 3px;
      }
      .el-input__inner {
        padding-left: 50px;
      }
      .selector {
        line-height: 53px;
        color: #333;
        margin-left: -5px;
      }
    }
    // 新用户注册和忘记密码
    &-password {
      margin-top: -16px;
      height: 16px;
      font-size: 14px;
      color: #666;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      user-select: none;
      &-forget {
        font-size: 14px;
      }
    }
    // 用户协议
    &-userAgreement {
      margin-top: 35px;
      text-align: center;
      font-size: 14px;
      .agreement {
        cursor: pointer;
        user-select: none;
        color: #0096ff;
      }
    }
  }
  // 提示
  // 提示
  &-message {
    z-index: 2;
    width: 340px;
    margin: -400px auto 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    padding: 8px 12px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
  }
}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff !important;
  color: #333;
}
// 输入框icon
.icon {
  margin: 15px 10px;
  width: 22px;
  height: 22px;
}
.login-pass {
  background-image: url('~a/login-pass.png');
}
.login-verify {
  background-image: url('~a/login-verify.png');
}
</style>
