<template>
  <!-- 关于我们 -->
  <el-dialog
    title="关于我们"
    :visible.sync="aboutUsDialogVisible"
    width="52.2vw"
    :close-on-click-modal="true"
    class="AboutUs"
  >
    <!-- 上部分 -->
    <div class="AboutUs-up">
      <!-- logo -->
      <i class="icon icon-aboutUs-logo" />
      <p class="AboutUs-up-title">当前版本：</p>
      <p class="AboutUs-up-text longmap">longmap V3.0.1</p>
      <p class="AboutUs-up-title">版权所有</p>
      <p class="AboutUs-up-text">神州龙空间技术（深圳）有限公司</p>
      <p class="AboutUs-up-title">版权声明</p>
      <p class="AboutUs-up-text">
        神州龙空间技术（深圳）有限公司
        （以下简称“神州龙”）拥有神州龙网站（www.longmap.com）内所有资料的版权，神州龙公司对其发行的包括但不限于产品或服务的全部内容及神州龙网站上的材料拥有版权等知识产权受法律保护。未经本公司书面许可，任何单位及个人不得以任何方式或理由对上述产品、服务、信息、材料的任何部分进行使用、复制、修改、抄录、传播或与其它产品捆绑使用、销售。
      </p>
    </div>
    <!-- 分割线 -->
    <div class="AboutUs-border" />
    <!-- 下部分 -->
    <div class="AboutUs-below">
      <!-- 商务合作 -->
      <p class="AboutUs-below-title">
        商务合作<span>（项目合作、功能定制）</span>
      </p>
      <div class="AboutUs-below-box">
        <div class="AboutUs-below-box-info">
          <div class="AboutUs-below-box-info-text">
            <i class="icon icon-aboutUs-name"></i>
            姓名：杨先生（营销总监）
          </div>
          <div class="AboutUs-below-box-info-text">
            <i class="icon icon-aboutUs-phone"></i>
            电话：13812369875
          </div>
          <div class="AboutUs-below-box-info-text t3">
            <i class="icon icon-aboutUs-weixin"></i>
            微信：12332196542
          </div>
          <div class="AboutUs-below-box-info-text t4">
            <i class="icon icon-aboutUs-mail"></i>
            邮箱：123321@qq.com
          </div>
        </div>
        <div class="AboutUs-below-box-QRcode">
          <i class="icon icon-aboutUS-QRcode"></i>
        </div>
      </div>
      <!-- 产品服务 -->
      <p class="AboutUs-below-title">
        产品服务<span>（售前咨询、售后服务、技术支持、问题反馈）</span>
      </p>
      <div class="AboutUs-below-box">
        <div class="AboutUs-below-box-info">
          <div class="AboutUs-below-box-info-text">
            <i class="icon icon-aboutUs-name"></i>
            姓名：何先生（运维经理）
          </div>
          <div class="AboutUs-below-box-info-text">
            <i class="icon icon-aboutUs-phone"></i>
            电话：13812369875
          </div>
          <div class="AboutUs-below-box-info-text t3">
            <i class="icon icon-aboutUs-weixin"></i>
            微信：12332196542
          </div>
          <div class="AboutUs-below-box-info-text t4">
            <i class="icon icon-aboutUs-mail"></i>
            邮箱：123321@qq.com
          </div>
        </div>
        <div class="AboutUs-below-box-QRcode">
          <i class="icon icon-aboutUS-QRcode"></i>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AboutUs',
  components: {},
  data() {
    return {};
  },
  computed: {
    aboutUsDialogVisible: {
      get() {
        return this.$store.state.common.aboutUsDialogVisible;
      },
      set(val) {
        this.$store.commit('common/setAboutUsDialogVisible', val);
      }
    }
  },
  methods: {},
  watch: {}
};
</script>

<style lang="less" scoped>
.AboutUs {
  margin-top: -88px;
  // 上
  &-up {
    width: 100%;
    max-height: 378px;
    overflow: hidden;
    margin-bottom: 25px;
    &-title {
      line-height: 16px;
      color: #666666;
      font-size: 16px;
      margin: 20px 0 10px;
    }
    &-text {
      line-height: 26px;
      font-size: 18px;
      color: #333333;
    }
    .longmap {
      font-weight: bold;
      color: #507cff;
    }
  }
  // 分割线
  &-border {
    position: absolute;
    margin-left: -40px;
    width: 100%;
    height: 1px;
    background: #d2d2d2;
  }
  // 下
  &-below {
    width: 100%;
    max-height: 388px;
    overflow: hidden;
    // 标题
    &-title {
      margin-top: 30px;
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      color: #333333;
      span {
        font-size: 16px;
        color: #666666;
      }
    }
    // 内容
    &-box {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      width: 920px;
      height: 124px;
      background: #f4f7ff;
      border: 1px solid #d3e1ff;
      border-radius: 6px;
      &-info {
        &-text {
          margin: 27px 0 0 20px;
          display: flex;
          align-items: center;
          font-size: 18px;
          .icon {
            margin-right: 10px;
          }
        }
        .t3 {
          position: absolute;
          margin: -75px 0 0 358px;
        }
        .t4 {
          position: absolute;
          margin: -25px 0 0 358px;
        }
      }
      &-QRcode {
        margin: auto 80px auto;
      }
    }
  }
}
</style>
