<template>
  <!-- 登录页 -->
  <div class="login">
    <!-- 顶部 -->
    <Header />
    <!-- 左边 -->
    <div class="login-left">
      <!-- 标题 -->
      <div class="login-left-title1">欢迎登录longmap平台</div>
      <div class="login-left-title2">开启3D地图新时代</div>
      <!-- 分割线 -->
      <div class="login-left-border" />
      <!-- 介绍 -->
      <div class="login-left-content">
        <div class="inline-block" v-for="i in info" :key="i">
          <div class="container">
            <div class="container-status"></div>
            <div class="container-content">{{ i }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <login-form class="login-right" />
    <!-- 关于我们窗口 -->
    <AboutUs />
  </div>
</template>

<script>
import Header from 'c/Header.vue';
import LoginForm from '../components/login/loginForm.vue';
import AboutUs from '../components/workbench/AboutUs.vue';
export default {
  name: 'Login',
  components: {
    Header,
    LoginForm,
    AboutUs
  },
  data() {
    return {
      // 介绍
      info: [
        '数据一键发布服务',
        '文件收纳管理',
        '自定义样式上传',
        '模型文件上传'
      ]
    };
  },
  created() {
    localStorage.guideState = '';
  }
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-image: url('../assets/home-one-bg.png');
  background-size: contain;
  display: flex;
  justify-content: space-between;
  // 左边
  &-left {
    width: 420px;
    margin: 290px 0 0 260px;
    // 标题1
    &-title1 {
      font-size: 28px;
      font-weight: 700;
      color: #999999;
    }
    // 标题2
    &-title2 {
      margin-top: 30px;
      color: #000000;
      font-weight: 800;
      font-size: 40px;
    }
    // 分割线
    &-border {
      margin-top: 30px;
      height: 1px;
      width: 100%;
      background: rgb(176, 176, 176);
    }
    // 介绍
    &-content {
      margin-top: 30px;
      .inline-block {
        display: inline-block;
        margin: 0 40px 20px 0;
      }
      .container {
        align-items: center;
        display: flex;
        width: 170px;
        &-status {
          background-color: #0096ff;
          border-radius: 9999px;
          height: 12px;
          width: 12px;
          margin-right: 8px;
        }
        &-content {
          flex: 1;
          font-size: 18px;
        }
      }
    }
  }
  // 右边
  &-right {
    margin: 230px 260px 0 0;
  }
}
</style>
